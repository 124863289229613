import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { LocalsqlService } from './service/localsql.service';
import { AuthenticationService } from './services/Authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'AGB', url: '/agb', icon: '/assets/icon/document.svg' },
    { title: 'Datenschutz', url: '/datenschutzerklaerung', icon: '/assets/icon/datenschutz.svg' },
    { title: 'Impressum', url: '/impressum', icon: '/assets/icon/information.svg' },
  ];

  public socialPages = [
    { title: 'Newsletter', url: 'https://www.achtknoten.de/', icon: '/assets/icon/email-1.svg' },
    { title: 'Instagram', url: 'https://www.instagram.com/achtknoten.de', icon: '/assets/icon/instagram.svg' },
  ];

  public coursePages = [
    { title: 'Knotenkurs', url: '/knotenkurs', icon: '/assets/icon/seeknoten.svg' }
  ];

  public userPages = [
    { title: 'Profil', url: '/profil', icon: '/assets/icon/nutzer.svg' },
    { title: 'Logout', url: '/logout', icon: '/assets/icon/ausloggen.svg' },
  ];

  constructor(private localSql: LocalsqlService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private platform: Platform,
    ) {
      this.initializeApp();
    }

  initializeApp() {
    this.platform.ready().then(() => {
      this.authenticationService.authState.subscribe(state => {
        console.log(state);
        if (state) {
          this.router.navigate(['dashboard'], {replaceUrl: true});
        } else {
          this.router.navigate(['login'], {replaceUrl: true});
        }
      });
    });
  }

  open(s){
    window.open(s.url, '_system', 'location=yes'); return false;
  }
}
