import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },

  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },

  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'impressum',
    loadChildren: () => import('./impressum/impressum.module').then(m => m.ImpressumPageModule)
  },
  {
    path: 'agb',
    loadChildren: () => import('./agb/agb.module').then(m => m.AgbPageModule)
  },
  {
    path: 'datenschutzerklaerung',
    loadChildren: () => import('./datenschutzerklaerung/datenschutzerklaerung.module').then(m => m.DatenschutzerklaerungPageModule)
  },
  {
    path: 'profil',
    loadChildren: () => import('./profil/profil.module').then(m => m.ProfilPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then(m => m.LogoutPageModule)
  },
  {
    path: 'knotenkurs',
    loadChildren: () => import('./knotenkurs/knotenkurs.module').then(m => m.KnotenkursPageModule)
  },
  {
    path: 'kontakt',
    loadChildren: () => import('./kontakt/kontakt.module').then(m => m.KontaktPageModule)
  },
  {
    path: 'topslider',
    loadChildren: () => import('./components/topslider/topslider.module').then(m => m.TopsliderPageModule)
  },
  {
    path: 'slidercontent',
    loadChildren: () => import('./components/slidercontent/slidercontent.module').then(m => m.SlidercontentPageModule)
  },
  {
    path: 'navigationlist',
    loadChildren: () => import('./components/navigationlist/navigationlist.module').then(m => m.NavigationlistPageModule)
  },
  {
    path: 'navigationelement',
    loadChildren: () => import('./components/navigationelement/navigationelement.module').then(m => m.NavigationelementPageModule)
  },
  {
    path: 'fuehrerscheinart',
    loadChildren: () => import('./fuehrerscheinart/fuehrerscheinart.module').then(m => m.FuehrerscheinartPageModule)
  },
  {
    path: 'course/:id',
    loadChildren: () => import('./fuehrerscheinart/fuehrerscheinart.module').then(m => m.FuehrerscheinartPageModule)
  },
  {
    path: 'fuehrerscheinkategorie',
    loadChildren: () => import('./fuehrerscheinkategorie/fuehrerscheinkategorie.module').then(m => m.FuehrerscheinkategoriePageModule)
  }, {
    path: 'course/:courseid/lesson/:lessonid',
    loadChildren: () => import('./fuehrerscheinkategorie/fuehrerscheinkategorie.module').then(m => m.FuehrerscheinkategoriePageModule)
  },
  {
    path: 'fuehrerscheinfrage',
    loadChildren: () => import('./fuehrerscheinfrage/fuehrerscheinfrage.module').then(m => m.FuehrerscheinfragePageModule)
  },
  {
    path: 'kategoriefortschritt',
    loadChildren: () => import('./components/kategoriefortschritt/kategoriefortschritt.module').then(m => m.KategoriefortschrittPageModule)
  },
  {
    path: 'fuhrerscheinthema',
    loadChildren: () => import('./fuhrerscheinthema/fuhrerscheinthema.module').then( m => m.FuhrerscheinthemaPageModule)
  },
  {
    path: 'course/:courseid/lesson/:lessonid/topic/:topicid',
    loadChildren: () => import('./fuhrerscheinthema/fuhrerscheinthema.module').then( m => m.FuhrerscheinthemaPageModule)
  },
  {
    path: 'fuhrerscheintest',
    loadChildren: () => import('./fuhrerscheintest/fuhrerscheintest.module').then( m => m.FuhrerscheintestPageModule)
  },
  {
    path: 'course/:courseid/lesson/:lessonid/topic/:topicid/quiz/:quizid',
    loadChildren: () => import('./fuhrerscheintest/fuhrerscheintest.module').then( m => m.FuhrerscheintestPageModule)
  },
  {
    path: 'course/:courseid/lesson/:lessonid/quiz/:quizid',
    loadChildren: () => import('./fuhrerscheintest/fuhrerscheintest.module').then( m => m.FuhrerscheintestPageModule)
  },  {
    path: 'questionslider',
    loadChildren: () => import('./components/questionslider/questionslider.module').then( m => m.QuestionsliderPageModule)
  }



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
